import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import "./styles.css";
export const _frontmatter = {
  "author": "Noah Wong",
  "date": "2020-05-01 UTC -8",
  "length": 2,
  "location": "Portland, Oregon",
  "path": "/background-image-with-gradient-overlay/",
  "title": "Background Image with Gradient Overlay",
  "tags": ["tech", "code"],
  "description": "How to use a image in a div background and add a color overlay so text can be easily read."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`The hero section on a webpage is intended to be the first element that a person should view on the page. If it does not immediately capture your attention and the message is quickly conveyed then it's not doing its job. `}</p>
    <p>{`When using an image as a background for a header, there needs to be enough contrast between the text and the background in order for it to be easily legible and just makes it look way better. From a design hierachy, this draws your eyes more to the text, which is usually more important, and does not get lost in the background image. `}</p>
    <div id="post-20200501-no-overlay">
  <h2>Sarplaninac Mountain Dog</h2>
  <a>Learn More</a>
    </div>
    <p>{`It is easy to add text over a background image in a div but adding a color overlay on top of that is a little trickier. By using `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`linear-gradient`}</code>{` on the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`background-image`}</code>{` property, you are able to add a color overlay.`}</p>
    <div id="post-20200501-with-overlay">
  <h2>Sarplaninac Mountain Dog</h2>
  <a>Learn More</a>
    </div>
    <div {...{
      "className": "gatsby-highlight has-highlighted-lines",
      "data-language": "css"
    }}><pre parentName="div" {...{
        "className": "language-css"
      }}><code parentName="pre" {...{
          "className": "language-css"
        }}><span parentName="code" {...{
            "className": "token selector"
          }}>{`div`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`height`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 400px`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`padding`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 1em`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`margin-bottom`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 1rem`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`display`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` flex`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`flex-direction`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` column`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`justify-content`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` center`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`align-items`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` center`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`background-image`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` #57585a`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{` `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`/* Fallback color. One solid color. */`}</span>{`
`}<span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`  `}<span parentName="span" {...{
              "className": "token property"
            }}>{`background-position`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` center`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`;`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`  `}<span parentName="span" {...{
              "className": "token property"
            }}>{`background-size`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` cover`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`;`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`  `}<span parentName="span" {...{
              "className": "token property"
            }}>{`background-image`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token function"
            }}>{`linear-gradient`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token function"
            }}>{`rgba`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span>{`0`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`0`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`0`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` 0.4`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span><span parentName="span" {...{
              "className": "token function"
            }}>{`rgba`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span>{`0`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`0`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`0`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` 0.7`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span><span parentName="span" {...{
              "className": "token url"
            }}><span parentName="span" {...{
                "className": "token function"
              }}>{`url`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`(`}</span>{`./sarplaninac.jpg`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`)`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`;`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token selector"
          }}>{`h2`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`text-align`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` center`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`color`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` #ffffff`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`letter-spacing`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 0.2em`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`margin-bottom`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 0.6em`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token selector"
          }}>{`a`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`background-color`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` #1753b2`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`color`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` #ffffff`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`border`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 2px solid #1753b2`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`border-radius`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 5px`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`padding`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 0.6em 1em`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`transition`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` all 0.5s ease`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`text-transform`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` uppercase`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`letter-spacing`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 0.1em`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`font-weight`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` bold`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`box-shadow`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 0 4px 6px -1px `}<span parentName="code" {...{
            "className": "token function"
          }}>{`rgba`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`0`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` 0`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` 0`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` 0.1`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` 0 2px 4px -1px `}<span parentName="code" {...{
            "className": "token function"
          }}>{`rgba`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`0`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` 0`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` 0`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` 0.06`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
 `}<span parentName="code" {...{
            "className": "token selector"
          }}>{`a:hover`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`background-color`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`rgba`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`255`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` 255`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` 255`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` 0.8`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`border`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 2px solid #1753b2`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`color`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` #1753b2`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`cursor`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` pointer`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "html"
    }}><pre parentName="div" {...{
        "className": "language-html"
      }}><code parentName="pre" {...{
          "className": "language-html"
        }}><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`div`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
  `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`h2`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`Sarplaninac Mountain Dog`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`h2`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
  `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`a`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`Learn More`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`a`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`div`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span></code></pre></div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      